<template>
  <v-container fluid fill-height>
    <v-layout justify-center>
      <v-flex>
        <perseu-card title="Listagem de Sequências">
          <v-btn
            color="secondary"
            slot="title-right"
            :to="{ name: sequencesFormPageName }"
          >
            Nova Sequência
          </v-btn>
          <template slot="content">
            <v-switch v-model="query.active" label="Ativos" />
            <v-data-table :items="items" :headers="headers" class="elevation-1">
              <template v-slot:item.type.name="{ item }">
                {{ item.isDefault ? 'Padrão -' : '' }} {{ item.type.name }}
              </template>
              <template v-slot:item.finalDate="{ item }">
                {{ item.finalDate | formatDate }}
              </template>
              <template v-slot:item.actions="props">
                <table-action-button
                  @click="edit(props.item)"
                  icon="edit"
                  text="Editar"
                />
                <table-action-button
                    v-if="!props.item.isDefault"
                    @click="setDefault(props.item)"
                    icon="check_circle"
                    text="Tornar padrão"
                />
                <table-action-button
                  v-if="props.item.active"
                  @click="activeOrDeactivate(props.item, false)"
                  icon="delete"
                  text="Desativar"
                />
                <table-action-button
                  v-else
                  @click="activeOrDeactivate(props.item, true)"
                  icon="check"
                  text="Ativar"
                />
              </template>
            </v-data-table>
            <p class="mt-5">
              Obs: Sequência padrão deve ser as criadas pelo Instituto Par Magnético e serão usadas quando o usuário quiser fazer a terapia montada por ele
            </p>
          </template>
        </perseu-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { find, save } from "@/services/sequences-service";
import { pagesNames } from "@/router/pages-names";
export default {
  data: () => ({
    items: [],
    headers: [
      { text: "Nome", value: "name" },
      { text: "Tipo", value: "type.name" },
      { text: "Criado por", value: "createdBy.name" },
      { text: "Ações", value: "actions", width: 200 },
    ],
    query: {
      active: true,
    },
  }),
  watch: {
    query: {
      handler() {
        this.list();
      },
      deep: true,
    },
  },
  computed: {
    sequencesFormPageName() {
      return pagesNames.SEQUENCES_FORM;
    }
  },
  methods: {
    async list() {
      try {
        this.$store.dispatch("loading/openDialog");
        let response = await find(this.query);
        this.items = response.data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async setDefault(item) {
      try {
        this.$store.dispatch("loading/openDialog");
        item.isDefault = true;
        await save(item);
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.list();
        this.$store.dispatch("loading/closeDialog");
      }
    },
    create() {
      this.$router.push({ name: pagesNames.SEQUENCES_FORM });
    },
    edit(obj) {
      this.$router.push({
        name: pagesNames.SEQUENCES_FORM,
        query: { id: obj.id },
      });
    },
    async activeOrDeactivate(item, target) {
      try {
        this.$store.dispatch("loading/openDialog");
        item.active = target;
        await save(item);
        this.removeFromList(item);
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    removeFromList(category) {
      let index = this.items.indexOf(category);
      this.items.splice(index, 1);
    },
  },
  created() {
    this.list();
  },
};
</script>

<style></style>
